import { Grid } from '@material-ui/core'
import ActionsToggleButton from 'components/ActionsToggleButton'
import Button from 'components/Buttons'
import ContentWrapper from 'components/ContentWrapper'
import RasterList from 'components/lists/rasterList'
import TopNavigation from 'components/navigation/TopNavigation'
import RasterEditDrawer from 'containers/drawers/RasterDrawer'
import { RasterModel } from 'data/models/RasterModel'
import { useRasterServices } from 'data/services'
import { createNotification } from 'factories/notificationFactory'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Dictionary } from 'types'
import { IRootReduxState } from 'types/reducers'
import { useIntl } from 'react-intl'
import ConfirmationDialog from 'containers/dialogs/ConfirmationDialog'

const RasterPage: React.FC = () => {
  const [rasters, setRasters] = React.useState<RasterModel[]>([])
  const [selectedRaster, setSelectedRaster] = React.useState<RasterModel | undefined>(undefined)
  const [deleteRaster, setDeleteRaster] = React.useState<number | undefined>(undefined);

  const intl = useIntl()
  const currentUser = useSelector((state: IRootReduxState) => state.app.user.current)
  const rasterService = useRasterServices()

  React.useEffect(() => {
    rasterService.getAllRaster().then((result) => {
      setRasters(result)
    })
    // eslint-disable-next-line
  }, [])

  const refreshRasters = (closeDrawer = true) => {
    rasterService.getAllRaster().then((result) => {
      closeDrawer && setSelectedRaster(undefined)
      setRasters(result)
    })
  }

  const createNew = () => {
    const raster = new RasterModel()
    raster.CompanyId = currentUser?.CompanyId
    setSelectedRaster(raster)
  }

  const handleCreate = (item: RasterModel) => {
    rasterService.createRaster(item).then(() => {
      createNotification.success('Raster Created Successfully')
      refreshRasters()
    })
  }

  const handleUpdate = (id: number, data: Dictionary<any>) => {
    rasterService.updateRaster(id, data as RasterModel).then(() => {
      createNotification.success(
        intl.formatMessage({
          id: 'update-success-raster',
          defaultMessage: 'Raster Updated Successfully',
        })
      )
      refreshRasters()
    })
  }

  const handleDelete = (id: number) => {
    rasterService.DeleteRaster(id).then(() => {
      createNotification.success(
        intl.formatMessage({
          id: 'delete-success-raster',
          defaultMessage: 'Raster Deleted Successfully',
        })
      )
      setDeleteRaster(undefined);
      refreshRasters()
    })
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Settings / Rasters</title>
      </Helmet>

      <TopNavigation
        componentsLeft={
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <ActionsToggleButton>
                <Button.CreateButton label={intl.formatMessage({ id: 'create.raster', defaultMessage: 'New Raster Collection' })} click={() => createNew()} />
              </ActionsToggleButton>
            </Grid>
          </Grid>
        }
      />
      
      <ConfirmationDialog
        message={
          <>
            {intl.formatMessage({ id: 'delete.raster.message', defaultMessage: 'Please confirm that you would like to delete this raster?' })}
          </>
        }
        title={intl.formatMessage({ id: 'delete.raster.title', defaultMessage: 'Delete Raster' })}
        open={deleteRaster !== undefined}
        handleCancel={() => setDeleteRaster(undefined)}
        handleOk={() => deleteRaster && handleDelete(deleteRaster)}
      />

      <ContentWrapper>
        <RasterList raster={rasters} handleSelect={(item) => setSelectedRaster(item)} />
      </ContentWrapper>
      <RasterEditDrawer setSelectedRaster={setSelectedRaster} selectedRaster={selectedRaster} handleCreate={handleCreate} handleUpdate={handleUpdate}
       handleDelete={id => setDeleteRaster(id)} />
    </React.Fragment>
  )
}

export default RasterPage
