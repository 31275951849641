import { WorkawareBaseClass } from './WorkawareBaseClass'

export class CourseModel extends WorkawareBaseClass {
    _className = 'CourseModel'
    public NotifyDaysInAdvance: number | undefined = undefined
    public NotificationLevel: number | undefined = undefined
    public SendSafetyManagersSummary: boolean
    public IsRequired: boolean
    public CreatedByUserId: number | undefined = undefined
    public CreatedOn: Date | undefined = undefined
    public Price: number | undefined = undefined
    public DurationInMinutes: number | undefined = undefined
    public RetrainingIntervalUnits: number | undefined = undefined
    public DefaultExpiryUnits: number | undefined = undefined
    public RetrainingInterval: number | undefined = undefined
    public DefaultExpiry: number | undefined = undefined
    public URL: string | undefined = undefined
    public Instructor: string | undefined = undefined
    public PreferredVendor: string | undefined = undefined
    public Description: string | undefined = undefined
    public Name: string | undefined = undefined
    public CompanyId : number | undefined = undefined
    public Id: number 
}