import { FormTemplateModel, FormTemplateGroupModel, AnonymousFormModel, CompanyFormModel } from 'data/models';
import { transform, TransformMethods } from 'data/transform';
import { Dictionary } from 'types';
import { WorkawareApi } from 'apis/WorkawareApi';
import { FormTemplateFilterArguments, PagedArguments, PagedResult } from 'types/apis';
import { DynamicFormDto } from 'data/dtos/DynamicFormDto';
import { DynamicFormWriteDto } from 'data/dtos/DynamicFormWriteDto';
import { TeamCompanyFormDto } from 'data/dtos/TeamCompanyFormDto';

export class formTemplatesService {
  private _api: WorkawareApi;
  private _anonymousTransform: TransformMethods<AnonymousFormModel>;
  private _templateTransform: TransformMethods<FormTemplateModel>;
  private _companyFormTransform: TransformMethods<CompanyFormModel>;
  private _groupTransform: TransformMethods<FormTemplateGroupModel>;

  constructor(api: WorkawareApi) {
    this._api = api;
    this._anonymousTransform = transform<AnonymousFormModel>(AnonymousFormModel);
    this._templateTransform = transform<FormTemplateModel>(FormTemplateModel);
    this._companyFormTransform = transform<CompanyFormModel>(CompanyFormModel);
    this._groupTransform = transform<FormTemplateGroupModel>(FormTemplateGroupModel);
  }

  all = () => new Promise<FormTemplateModel[]>((resolve, reject) => {
    this._api.forms.templates.all()
      .then(result => resolve(result.Values))
      .catch(error => reject(error));
  });

  activeTemplates = () => new Promise<FormTemplateModel[]>((resolve, reject) => {
    this._api.forms.templates.active()
      .then(result => resolve(this._templateTransform.arrayFrom(result.value)))
      .catch(error => reject(error));
  });

  grouped = () => new Promise<FormTemplateGroupModel>((resolve, reject) => {
    this._api.forms.templates.grouped()
      .then(result => resolve(this._groupTransform.from(result)))
      .catch(error => reject(error))
  });

  mapped = () => new Promise<Dictionary<FormTemplateModel>>((resolve, reject) => {
    this._api.forms.templates.active()
      .then(result => resolve(this._templateTransform.mapFrom(result.value, 'Id')))
      .catch(error => reject(error));
  });

  searchTags = (search: string = '', count: number = 5) => new Promise<string[]>((resolve, reject) => {
    this._api.forms.templates.search.tags(search, count)
      .then(result => resolve(result))
      .catch(error => reject(error));
  });

  searchNotificationLevels = (search: string = '', count: number = 5) => new Promise<Dictionary<string>>((resolve, reject) => {
    this._api.forms.templates.search.notificationLevels(search, count)
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
  getAll = () => new Promise<CompanyFormModel[]>((resolve, reject) => {
    this._api.forms.templates.getAll()
      .then(form => resolve(form.value))
      .catch(error => reject(error))
  })

  get = (id: number) => new Promise<CompanyFormModel | undefined>((resolve, reject) => {
    this._api.forms.templates.get(id)
      .then(form => resolve(form))
      .catch(() => resolve(undefined))
  })

  getTemplate = (id: number) => new Promise<CompanyFormModel>((resolve, reject) => {
    this._api.forms.templates.get(id)
      .then(form => resolve(form))
      .catch(error => reject(error));
  });

  addTemplate = (template: CompanyFormModel) => new Promise<CompanyFormModel>((resolve, reject) => {
    this._api.forms.templates.insert(template)
      .then(form => resolve(form))
      .catch(error => reject(error));
  });

  updateTemplate = (template: CompanyFormModel) => new Promise<CompanyFormModel>((resolve, reject) => {
    this._api.forms.templates.update(template)
      .then(form => resolve(form))
      .catch(error => reject(error));
  });

  deleteTemplate = (id: number) => new Promise<any>((resolve, reject) => {
    this._api.forms.templates.delete(id)
      .then(form => resolve(form))
      .catch(error => reject(error));
  });

  getDynamicTemplate = (id: number) => new Promise<DynamicFormDto>((resolve, reject) => {
    this._api.forms.dynamic.template(id)
      .then(form => resolve(form))
      .catch(error => reject(error));
  });

  paged = (args: PagedArguments & { dynamic: boolean, showAll: boolean }) => new Promise<PagedResult<CompanyFormModel>>((resolve, reject) => {
    this._api.forms.templates.paged(args)
      .then(result => resolve({
        Count: result.Count,
        Values: this._companyFormTransform.arrayFrom(result.Values)
      }))
      .catch(error => reject(error));
  });

  pagedFilteredTagged = (args: PagedArguments & FormTemplateFilterArguments) => new Promise<PagedResult<CompanyFormModel>>((resolve, reject) => {
    this._api.forms.templates.pagedFilteredTagged(args)
      .then(result => resolve({
        Count: result.Count,
        Values: this._companyFormTransform.arrayFrom(result.Values)
      }))
      .catch(error => reject(error));
  });


  pagedDynamicTemplates = () => this.paged({
    offset: 0,
    take: 999,
    dynamic: true,
    showAll: false
  })


  anonymous = () => new Promise<AnonymousFormModel[]>((resolve, reject) => {
    this._api.forms.anonymous.all()
      .then(result => resolve(this._anonymousTransform.arrayFrom(result)))
      .catch(error => reject(error));
  });

  createAnonymous = (data: AnonymousFormModel) => new Promise<AnonymousFormModel>((resolve, reject) => {
    this._api.forms.anonymous.create(data)
      .then((form) => resolve(Object.assign(new AnonymousFormModel(), form)))
      .catch(error => reject(error));
  });

  deleteAnonymous = (id: string) => new Promise<void>((resolve, reject) => {
    this._api.forms.anonymous.delete(id)
      .then(() => resolve())
      .catch(error => reject(error));
  });

  updateAnonymous = (form: AnonymousFormModel): Promise<AnonymousFormModel> => {
    return new Promise((resolve, reject) => {
      if (form.Id === undefined) {
        return reject('form id must be defined');
      }
      this._api.forms.anonymous.update(form.Id, form)
        .then((form) => resolve(Object.assign(new AnonymousFormModel(), form)))
        .catch((error: any) => reject(error));
    });
  };

  post = (data: DynamicFormWriteDto) => new Promise<DynamicFormWriteDto>((resolve, reject) => {
    this._api.forms.templates.post(data)
      .then(result => resolve(result))
      .catch(error => reject(error));
  });

  put = (data: DynamicFormWriteDto) => new Promise<DynamicFormWriteDto>((resolve, reject) => {
    this._api.forms.templates.put(data.id, data)
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
  
  getAssociatedTeams =(id :number) => new Promise<TeamCompanyFormDto[]>((resolve ,reject) => {
    if(id == undefined) {
      return reject('id is undefinded');
    }
    this._api.forms.associatedTeams.get(id)
    .then((result) => resolve(result))
    .catch(error => reject(error));
  });

  putAssociatedTeams = (teamCompanyForm : TeamCompanyFormDto[], id : number) => new Promise<TeamCompanyFormDto[]>((resolve , reject) => {
    if(teamCompanyForm==undefined || id== undefined) {
      return reject('data is undefinded')
    }
    this._api.forms.associatedTeams.post(teamCompanyForm,id)
    .then((result) => resolve(result))
    .catch((error => reject(error)));
  });
}