import {CameraDto} from "data/dtos/CameraDto";
import {WorkawareApi} from "../../apis/WorkawareApi"

export class cameraSettingsService {


    private _api:WorkawareApi 
    constructor(api: WorkawareApi)
    {
        this._api=api
    }
    getList = () =>{
        return new Promise<CameraDto[]>((resolve,reject) => {
            this._api.cameraSettings.get()
            .then(result => resolve(result))
            .catch(error => reject(error))
        })
    }
    postCameraSettings = (data: CameraDto) =>{
        return new Promise<CameraDto>((resolve,reject) =>{
            this._api.cameraSettings.post(data)
            .then(result => resolve(result))
            .catch(error => reject(error))
        })
    }
    putCameraSettings =(key:number,data:CameraDto) => {
        return new Promise<CameraDto>((resolve,reject) => {
            this._api.cameraSettings.put(data)
            .then(result => resolve(result))
            .catch(error => reject(error))

        })
    }
    deleteCameraSettings = (key:number) => {
        return new Promise<any>((resolve,reject) => {
            this._api.cameraSettings.delete(key)
            .then(result => resolve(result))
            .catch(error => reject(error))
        })
    }
}