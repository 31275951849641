import { WorkawareApi } from 'apis/WorkawareApi'
import { CounterModel } from 'data/models/CounterModel'
import { transform, TransformMethods } from 'data/transform'

export class counterService {
  private _api: WorkawareApi
  private _counterTransform: TransformMethods<CounterModel>

  constructor(api: WorkawareApi) {
    this._api = api
    this._counterTransform = transform<CounterModel>(CounterModel)
  }
  getAll = () => {
    return new Promise<CounterModel[]>((resolve, reject) => {
      this._api.counters
        .get()
        .then((result) => resolve(this._counterTransform.arrayFrom(result.value)))
        .catch((error) => reject(error))
    })
  }
  createCounter =(counter:CounterModel) =>
  {
    return new Promise<CounterModel>((resolve,reject) =>
    {
      this._api.counters
          .create(counter)
          .then((counter) =>resolve(this._counterTransform.from(counter)))
          .catch((error) =>reject(error))
    })
  }
 
  updateCounter = (data: CounterModel) => {
    return new Promise((resolve, reject) => {
      this._api.counters
        .update(data.Id, data)
        .then((item) => resolve(this._counterTransform.from(item)))
        .catch((error) => reject(error))
    })
  }
 

}
