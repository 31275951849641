import React from 'react';
import { verticalHeader } from '../../components/style/shared';
import Drawer from '@material-ui/core/Drawer';
import { useIntl } from 'react-intl';
import { Dictionary } from 'types';
import { ShapeFileModel } from 'data/models/ShapefileModel';
import ShapeFileEditForm from 'containers/forms/ShapeFileEditForm';
import {  CompanyFormModel, PicklistItemModel } from 'data/models';

const containerStyle = { 
  maxWidth: 500, 
  minWidth: 300, 
  margin: 20, 
  marginLeft: 40 
};

interface Props {
  selectedShapeFile?: ShapeFileModel,
  shapefileGroupNames:PicklistItemModel[]
  forms:CompanyFormModel[],
  setSelectedShapeFile: (shapeFile?: ShapeFileModel) => void,
  handleCreate: (raster: ShapeFileModel) => void, 
  handleUpdate: (id: number, changes: Dictionary<any>, something?: boolean) => void
  handleDelete: (id: number) => void, 
};

const ShapeFileDrawer = (props: Props) => {
  const { selectedShapeFile,forms,shapefileGroupNames,setSelectedShapeFile, handleCreate, handleUpdate,handleDelete } = props;
  const intl = useIntl();
  return (
    <Drawer anchor="right" open={selectedShapeFile !== undefined} onClose={() => setSelectedShapeFile(undefined)}>
      <div role="presentation" style={containerStyle}>
        <div style={verticalHeader(300)}>{intl.formatMessage({ id: "shapefile.details", defaultMessage: "Shape File Details" })}</div>
        {selectedShapeFile && (
          <ShapeFileEditForm
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            handleCancel={() => setSelectedShapeFile(undefined)}
            item={selectedShapeFile}
            forms={forms}
            shapefileGroupNames={shapefileGroupNames}
          />
        )}
      </div>
    </Drawer>
  );
};

export default ShapeFileDrawer;