export class LMSCourseSummaryStatusDto
 {
     public UserTrainingProfile :UserTrainingProfileDto
     public Status :LMSCourseStatus
     public PercentCompleted : number
     public EnrolledOnDate : Date
     public DateCompleted : Date
	 }


export class LMSCourseStatusDto extends LMSCourseSummaryStatusDto
{
    public Course : LMSCourse
 }
 
 export class LMSCourse
 {
     public Id : number
     public Name :string
     public Description :string
     public LMSCourseId :number
     public RestrictedToCompanyId : number
     public CreationDate : Date
     public Price : string
     public TimeLimitMinutes : string
     public AvatarURL : string
     public BigAvatarURL : string
     public CertificationDuration : string
 }
 
 

export class UserTrainingProfileDto
 {
     public ShowInTrainingMatrix : boolean
     public SendTrainingNotification:boolean
     public UserID :number
     public IsActive:boolean
     public BestName :string
     public UserName: string
     public BestEmailAddress: string
     public BestSMSPhone: string
 
	 }


export enum LMSCourseStatus
{
    Unknown,
    NotPassed,
    Completed,
    NotStarted,
    InProgress,
    Expired,
}