import { PagedResult } from 'types/apis'
import {WorkawareApi} from '../../apis/WorkawareApi'
import { transform, TransformMethods } from '../../data/transform'
import { EmailLogModel } from '../models/EmailLogModel'

export class emailLogService {

    private _api : WorkawareApi
    private emailLogTransform : TransformMethods<EmailLogModel>
    constructor(api: WorkawareApi){
        this._api = api
        this.emailLogTransform = transform<EmailLogModel>(EmailLogModel)
    }
    getList = (page:number, rowsPerPage:number) =>{
        return new Promise<PagedResult<EmailLogModel>>((resolve,reject) =>{
            this._api.emailLog.get(page,rowsPerPage)
            .then(result =>resolve({
                Count: result['@odata.count'] as number,
                Values: this.emailLogTransform.arrayFrom(result.value)
            }))
            .catch(error => reject(error))
        }) 
    }
    resendEmail =(emailLogId:number) =>{
        return new Promise<any>((resolve,reject) =>{
            this._api.emailLog.resendEmail(emailLogId)
            .then(result =>resolve(result))
            .catch(error => reject(error))
        })
    }
}