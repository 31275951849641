import { transform, TransformMethods } from 'data/transform'
import { WorkawareApi } from 'apis/WorkawareApi'
import { UserGeneratedShapeModel } from 'data/models/UserGeneratedShapeModel'
import { copyProperties } from 'utils/utilities'

export class userGeneratedShapesService {
  private _api: WorkawareApi
  private _shapeTransform: TransformMethods<UserGeneratedShapeModel>

  constructor(api: WorkawareApi) {
    this._api = api
    this._shapeTransform = transform<UserGeneratedShapeModel>(UserGeneratedShapeModel)
  }

  createShape = async (userShape: UserGeneratedShapeModel) => {
    return new Promise<UserGeneratedShapeModel>((resolve, reject) => {
      this._api.userGeneratedShapes
        .post(userShape)
        .then((userShape) => resolve(this._shapeTransform.from(userShape)))
        .catch((error) => reject(error))
    })
  }

  getShapes = async (): Promise<UserGeneratedShapeModel[]> => {
    const results = await this._api.userShapes.getUserShapes()
    return results.results.map((entry) => copyProperties(new UserGeneratedShapeModel(), entry))
  }

  updateShape = async (id: string, modifications: any) => {
    const result = await this._api.userGeneratedShapes.update(id, modifications)
    return copyProperties(new UserGeneratedShapeModel(), result)
  }

  deleteShape = async (id: string) => {
    await this._api.userGeneratedShapes.delete(id)
  }
}
