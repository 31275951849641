import { WaoEmptyListPlaceholder } from 'components/Forms'
import ShapeFilesListItem from 'components/list-items/ShapeFilesListItem'
import CustomList from 'components/lists/CustomList'
import { ShapeFileModel } from 'data/models/ShapefileModel'
import React from 'react'

interface Props {
  shapeFile: ShapeFileModel[]
  filterText?: string
  selectedShapeFiles?: ShapeFileModel[]
  handleSelect?: (shapeFile: ShapeFileModel) => void
  multiSelect?: boolean
}
const ShapeFilesList: React.FC<Props> = ({ shapeFile, selectedShapeFiles = [], handleSelect, multiSelect = false }) => {
  return (
    <React.Fragment>
      {shapeFile.length > 0 && (
        <CustomList ariaLabel="Shape List">
          {shapeFile.map((item) => (
            <ShapeFilesListItem multiSelect={multiSelect} selected={selectedShapeFiles.includes(item)} key={item.Id} handleSelect={handleSelect} shapeFile={item} />
          ))}
        </CustomList>
      )}

      {(!shapeFile || shapeFile.length === 0) && <WaoEmptyListPlaceholder />}
    </React.Fragment>
  )
}

export default ShapeFilesList
