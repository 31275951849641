import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'components/Buttons';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props extends DialogProps {
  message: string | JSX.Element,
  handleOk?: () => void,
  handleCancel?: () => void
}

const ConfirmationDialog: React.FC<Props> = ({ open, message, title = <FormattedMessage id="confirmation" defaultMessage="Please Confirm" />, handleOk, handleCancel, ...props }) => {
  return (
    <Dialog
      { ...props }
      open={open === true}
      onClose={() => handleCancel && handleCancel()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button.YesButton click={() => handleOk && handleOk()} />
        <Button.NoButton click={() => handleCancel && handleCancel()} />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
