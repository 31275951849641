import React from 'react'
import ListItemBase from 'components/list-items/ListItemBase'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import { UserGeneratedShapeModel } from 'data/models/UserGeneratedShapeModel'
import { useTheme } from '@material-ui/core'
import { shapeType } from 'enums/shapeType'

export interface RasterListItemProps {
  shape: UserGeneratedShapeModel
  selected?: boolean
  multiSelect?: boolean
  handleSelect?: (Shape: UserGeneratedShapeModel) => void
  showDetail?: boolean
}

const getShapeType = (shapeTypeId: number) => {
  switch (shapeTypeId) {
    case shapeType.Polygon:
      return 'Polygon'
    case shapeType.Line:
      return 'Line'
    case shapeType.Point:
      return 'Point'
    default:
      return 'Unknown'
  }
}

const UserGeneratedListitem = ({ shape, selected, handleSelect, multiSelect = false }: RasterListItemProps) => {
  const theme = useTheme()

  const getTag = (name: string, value: any) => {
    const mod = theme.palette.type === 'dark' ? 'is-dark' : 'is-light'
    return (
      <span className={`tag is-lowercase mr-1 is-success  ${mod}`}>
        {name}: {value}
      </span>
    )
  }
  const getSecondaryText = (shape: UserGeneratedShapeModel) => {
    if (!shape) {
      return ''
    }
    return (
      <>
        {getTag('Type', getShapeType(shape.Type))}
        {getTag('Created By', shape.CreatedByUserName)}
      </>
    )
  }

  return (
    <ListItemBase multiSelect={multiSelect} handleClick={() => handleSelect && handleSelect(shape)} selected={selected}>
      <ListItem
        onClick={(e) => {
          e.preventDefault()
          handleSelect && handleSelect(shape)
        }}
      >
        <ListItemAvatar>
          <DynamicFeedIcon color={shape.IsDraft ? 'secondary' : 'disabled'} />
        </ListItemAvatar>
        <ListItemText primary={shape.Name} secondary={getSecondaryText(shape)} />
      </ListItem>
    </ListItemBase>
  )
}

export default UserGeneratedListitem
