import { TablePagination } from '@material-ui/core'
import ContentWrapper from 'components/ContentWrapper'
import ShapeFilesList from 'components/lists/ShapeFilesList'
import TopNavigation from 'components/navigation/TopNavigation'
import PaginationActions from 'components/tables/paginationActions'
import ShapeFileDrawer from 'containers/drawers/ShapeFileDrawer'
import {  useCachedShapeFileGroupsPicklistItems } from 'data/cache'
import { CompanyFormModel, PicklistItemModel } from 'data/models'
import { ShapeFileModel } from 'data/models/ShapefileModel'
import { useFormTemplatesServices, useShapeFilesService } from 'data/services'
import { uiSettings } from 'etc/settings'
import { createNotification } from 'factories/notificationFactory'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Dictionary } from 'types'
const ShapeFilesPage: React.FC = () => {
  const [selectedShapeFile, setSelectedShapeFile] = React.useState<ShapeFileModel | undefined>(undefined)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(uiSettings.pagination.defaultRows)
  const [page, setPage] = React.useState<number>(0)
  const [pageOfUserGeneratedShapes, setPageOfUserGeneratedShapes] = React.useState<ShapeFileModel[]>([])
  const [shapeFiles, setShapeFiles] = React.useState<ShapeFileModel[]>([])
  const [completedForms, setCompletedForms] = React.useState<CompanyFormModel[]>([])
  const [shapefileGroupNames, setShapefileGroupNames] = React.useState<PicklistItemModel[]>([])

  const shapeFileService = useShapeFilesService()
  const formTemplatesService = useFormTemplatesServices()
  const picklistItemsService =useCachedShapeFileGroupsPicklistItems()
  const handleCreate= async (data:ShapeFileModel) => {
    if ( data) {
      await shapeFileService.addShape(data).then(()=>{
        createNotification.success('Shape Added Successfully')
        setSelectedShapeFile(undefined)
        getData()
      })
    }
  }
  const handleUpdate = async (id: number, data: Dictionary<any>) => {
    if (id && data) {
      await shapeFileService.updateShape(id, data as ShapeFileModel)
      createNotification.success('Shape Updated Successfully')
      setSelectedShapeFile(undefined)
      getData()
    }
  }

  const handleDelete = async (id: number) => {
    if (id) {
      await shapeFileService.deleteShape(id)
      createNotification.success('shape item deleted')
      setSelectedShapeFile(undefined)
      getData()
    }
  }

  const setPagedData = () => {
    const start = page * rowsPerPage
    const end = Math.min(start + (rowsPerPage - 1), shapeFiles.length)
    setPageOfUserGeneratedShapes(shapeFiles.slice(start, end))
  }

  const getData = async () => {
    setShapefileGroupNames(picklistItemsService)
    shapeFileService.getShapeFile().then((shapes) =>{ setShapeFiles(shapes)})
    formTemplatesService.getAll().then((forms) =>{ setCompletedForms(forms)})
  }

  React.useEffect(() => {
    setPagedData()
    // eslint-disable-next-line
  }, [page, rowsPerPage, shapeFiles])

  React.useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Helmet>
        <title>Settings / Shape Files</title>
        <meta name="shape files" content="shape files" />
      </Helmet>

      <TopNavigation />

      <ContentWrapper>
        <ShapeFilesList shapeFile={pageOfUserGeneratedShapes} handleSelect={(shape) => setSelectedShapeFile({ ...shape })} />
        <TablePagination
          rowsPerPageOptions={uiSettings.pagination.rowsOptions}
          count={shapeFiles?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          component="div"
          onChangePage={(e, page) => setPage(page)}
          onChangeRowsPerPage={(e) => {
            setRowsPerPage(+e.target.value)
            setPage(0)
          }}
          ActionsComponent={PaginationActions}
        />
      </ContentWrapper>

      {selectedShapeFile && (
      <ShapeFileDrawer forms={completedForms && completedForms} shapefileGroupNames={shapefileGroupNames} setSelectedShapeFile={setSelectedShapeFile} selectedShapeFile={selectedShapeFile} handleCreate={handleCreate} handleUpdate={handleUpdate} handleDelete={handleDelete}/>
      )}
    </>
  )
}

export default ShapeFilesPage
