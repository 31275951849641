import { Grid } from '@material-ui/core'
import Button from 'components/Buttons'
import { WaoCheckbox, WaoFormContainer, WaoIntegerInput, WaoMenu, WaoSelect, WaoText } from 'components/Forms'
import { CompanyFormModel, PicklistItemModel } from 'data/models'
import { ShapeFileModel } from 'data/models/ShapefileModel'
import { Dictionary } from 'data/transform'
import { StyleForm } from 'data/lists/StyleForm'
import { shapeType } from 'enums/shapeType'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  item: ShapeFileModel
  forms: CompanyFormModel[]
  shapefileGroupNames: PicklistItemModel[]
  handleCreate?: (raster: ShapeFileModel) => void
  handleUpdate?: (id: number, changes: Dictionary<any>, something?: boolean) => void
  handleCancel?: () => void
  handleDelete?: (id: number) => void
}
const ShapeFileEditForm = ({ item, forms, shapefileGroupNames, handleCreate, handleUpdate, handleCancel, handleDelete }: Props) => {
  const [selectedShapeFile, setSelectedShapeFile] = React.useState<ShapeFileModel>(Object.assign(new ShapeFileModel(), item))
  const [modified, setModified] = React.useState<Dictionary<any>>({})
  const editing = Boolean(selectedShapeFile.Id)
  const dirty = () => 0 < Object.keys(modified).length
  const handleChange = (name: string) => (value: any) => mutateState(name, value)
  const mutateState = (name: string, value: any) => {
    setModified({ ...modified, [name]: value })
    setSelectedShapeFile({ ...selectedShapeFile, [name]: value })
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    editing ? handleUpdate && selectedShapeFile.Id && handleUpdate(selectedShapeFile.Id, selectedShapeFile) : handleCreate && handleCreate(selectedShapeFile)
  }

  return (
    <WaoFormContainer name="user-edit-form" submit={(e) => handleSubmit(e)}>
      {editing && (
        <WaoMenu
          items={[
            {
              name: 'delete',
              control: <Button.DeleteButton fullWidth click={() => handleDelete && handleDelete(selectedShapeFile.Id)} />,
            },
          ]}
        />
      )}

      <Grid container direction="column" spacing={3}>
        <Grid item>
          <WaoText name="Name" value={selectedShapeFile.Name} handleChange={handleChange} label="Name" />
        </Grid>
        <Grid item>
          <WaoText name="Description" value={selectedShapeFile.Description} handleChange={handleChange} label="Description" />
        </Grid>
        <Grid item>
          <WaoSelect name="GroupNamePicklistId" value={selectedShapeFile.GroupNamePicklistId} handleChange={handleChange} label="Group Name" items={shapefileGroupNames?.map((list, i) => ({ name: list.Value || '', value: list.Id }))} />
        </Grid>

        <Grid item>
          <WaoText name="Filename" value={selectedShapeFile.Filename} handleChange={handleChange} label="File Name" />
        </Grid>
        <Grid item>
          <WaoIntegerInput name="MinimumZoomVisible" value={selectedShapeFile.MinimumZoomVisible?.toString()} handleChange={handleChange} label={<FormattedMessage id="min-zoom" defaultMessage="Min Zoom Level" />} />
        </Grid>
        <Grid item>
          <WaoIntegerInput name="MaximumZoomVisible" value={selectedShapeFile.MaximumZoomVisible?.toString()} handleChange={handleChange} label={<FormattedMessage id="max-zoom" defaultMessage="Max Zoom" />} />
        </Grid>
        <Grid item>
          <WaoIntegerInput name="Filesize" value={selectedShapeFile.Filesize?.toString()} handleChange={handleChange} label={<FormattedMessage id="max-zoom" defaultMessage="File size" />} />
        </Grid>
        <Grid item>
          <WaoSelect name="LockToCompanyFormId" value={selectedShapeFile.LockToCompanyFormId} handleChange={handleChange} label="Lock To Company Form" items={forms.map((list) => ({ name: list.Name || '', value: list.Id }))} />
        </Grid>
        <Grid item>
          <WaoSelect name="StyleCompanyFormId" value={selectedShapeFile.StyleCompanyFormId} handleChange={handleChange} label="Style Company Form" items={StyleForm.map((entry) => entry)} />
        </Grid>
        <Grid item>
          <WaoSelect
            name="StyleTypeId"
            value={selectedShapeFile.StyleTypeId}
            handleChange={handleChange}
            label="Type"
            items={Object.keys(shapeType)
              .filter((key) => !isNaN(Number(shapeType[key])))
              .map((v, i) => ({ name: v, value: i }))}
          />
        </Grid>

        <Grid item>
          <WaoText name="ToolTipFormat" value={selectedShapeFile.ToolTipFormat} handleChange={handleChange} label="Tool Tip Format" />
        </Grid>

        <Grid item>
          <WaoIntegerInput name="DisplayOrder" value={selectedShapeFile.DisplayOrder?.toString()} handleChange={handleChange} label={<FormattedMessage id="display-order" defaultMessage="Display Order" />} />
        </Grid>
        <Grid item>
          <WaoIntegerInput name="LineBufferInMeters" value={selectedShapeFile.LineBufferInMeters?.toString()} handleChange={handleChange} label={<FormattedMessage id="LineBufferInMeters" defaultMessage="Line Buffer (meters)" />} />
        </Grid>
        <Grid item>
          <WaoText name="ViewDynamicExpression" value={selectedShapeFile.ViewDynamicExpression} handleChange={handleChange} label="View Dynamic Expression" />
        </Grid>
        <Grid item>
          <WaoCheckbox name="ClusteringEnabled" value={selectedShapeFile.ClusteringEnabled} handleChange={handleChange} label="Clustering Points" />
        </Grid>
        <Grid item>
          <WaoCheckbox name="DefaultVisibleOnMap" value={selectedShapeFile.DefaultVisibleOnMap} handleChange={handleChange} label="Default Visible On Map" />
        </Grid>
        <Grid item>
          <WaoCheckbox name="HasPointPNGIcon" value={selectedShapeFile.HasPointPNGIcon} handleChange={handleChange} label="Has Point PNG Icon" />
        </Grid>
        <Grid item>
          <WaoCheckbox name="IsActive" value={selectedShapeFile.IsActive} handleChange={handleChange} label="Is Active" />
        </Grid>
        <Grid item>
          <WaoCheckbox name="HasDBF" value={selectedShapeFile.HasDBF} handleChange={handleChange} label="Has DBF" />
        </Grid>
        <Grid item>
          <WaoCheckbox name="HasPRJ" value={selectedShapeFile.HasPRJ} handleChange={handleChange} label="Has PRJ" />
        </Grid>
        <Grid item>
          <WaoCheckbox name="HasSHX" value={selectedShapeFile.HasSHX} handleChange={handleChange} label="Has SHX" />
        </Grid>
        <Grid item>
          <WaoCheckbox name="HasSQLiteDB" value={selectedShapeFile.HasSQLiteDB} handleChange={handleChange} label="Has SQLite DB" />
        </Grid>
      </Grid>
      <Button.SubmitButton disabled={!dirty()} label={editing ? <FormattedMessage id="update" defaultMessage="Update" /> : <FormattedMessage id="create" defaultMessage="Create" />} />
    </WaoFormContainer>
  )
}

export default ShapeFileEditForm
