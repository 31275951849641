import { WorkawareBaseClass } from './WorkawareBaseClass'
import uuid from 'uuid/v1'

export class CounterModel extends WorkawareBaseClass {
  _className = 'CounterModel'
  public UniqueId : uuid | undefined;
  public Id : number;
  public CompanyId : number | undefined;
  public Name : string | undefined;
  public Value: number| undefined;
  public IncreaseAllowed : boolean| undefined;
  public DecreaseAllowed : boolean | undefined
}

