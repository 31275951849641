import { apiKeys } from 'etc/settings';
import axios from 'axios'



export interface AddressResult {
  addressLine: string
  adminDistrict: string
  countryRegion: string
  countryRegionIso2: string
  formattedAddress: string
  locality: string
  postalCode: string
}
export interface GeoCodeResult {
    authenticationResultCode: string
    brandLogoUri: string
    copyright: string
    resourceSets: {
      estimatedTotal: number
      resources: {
        address: AddressResult | undefined
        bbox: number[]
        confidence: string
        entityType: string
        matchCodes: string[]
        name: string
        geocodePoints: {
          calculationMethod: string
          coordinates: number[]
          type: string
          usageTypes: string[]
        }[]
        point: {
          coordinates: number[]
          type: string
        }
      }[]
    }[]
    statusCode: number
    statusDescription: string
    traceId: string
}
export interface MapCoordinates {
    id: string
    Comments?: string | undefined
    Latitude: number
    Longitude: number
    RelatedFormId?: number | undefined
}
export interface MapPoint {
    altitude?: number
    altitudeReference?: number
    latitude?: number
    longitude?: number
}

export class mappingService{
  constructor(){
  }
  reverseGeocode = async (lat: number, lon: number) =>  {
    var result = await axios.get<GeoCodeResult | undefined>(`https://dev.virtualearth.net/REST/v1/Locations/${lat},${lon}?key=${apiKeys.bing}`)
    return result.data?.resourceSets[0]?.resources[0]?.address
  }
  
  addressLookupDS = async (query): Promise<AddressResult[] | undefined> => {
    const result = await axios.get<AddressResult[] | undefined>(`https://dev.virtualearth.net/REST/v1/Autosuggest?query=${query}&includeEntityTypes=Address&key=${apiKeys.bing}`)
      return result.status === 200 ? result[0] : null;
    }
}