
import { WorkawareApi } from 'apis/WorkawareApi'
import { LMSCourseStatusDto } from 'data/models/LMSCourseSummaryDto'
import { transform, TransformMethods } from 'data/transform'

export class userCourseService {
  private _api: WorkawareApi
  private _userCourseTransform: TransformMethods<LMSCourseStatusDto>

  constructor(api: WorkawareApi) {
    this._api = api
    this._userCourseTransform = transform<LMSCourseStatusDto>(LMSCourseStatusDto)
  }
  getCurrentUserCourses = () => {
    return new Promise<any>((resolve, reject) => {
      this._api.UserCourses
        .get()
        .then((result)=> resolve (result))
        .catch((error) => reject(error))
    })
  }
}