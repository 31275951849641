import React from 'react';
import { verticalHeader } from '../../components/style/shared';
import Drawer from '@material-ui/core/Drawer';
import { useIntl } from 'react-intl';
import { Dictionary } from 'types';
import RasterEditForm from 'containers/forms/RasterEditForm';
import { RasterModel } from 'data/models/RasterModel';

const containerStyle = { 
  maxWidth: 350, 
  minWidth: 300, 
  margin: 20, 
  marginLeft: 40 
};

interface Props {
  selectedRaster?: RasterModel,
  setSelectedRaster: (raster?: RasterModel) => void,
  handleCreate: (raster: RasterModel) => void, 
  handleUpdate: (id: number, changes: Dictionary<any>, something?: boolean) => void
  handleDelete: (id: number) => void
};

const RasterEditDrawer = (props: Props) => {
  const { selectedRaster,setSelectedRaster, handleCreate, handleUpdate,handleDelete } = props;
  const intl = useIntl();
  return (
    <Drawer anchor="right" open={selectedRaster !== undefined} onClose={() => setSelectedRaster(undefined)}>
      <div role="presentation" style={containerStyle}>
        <div style={verticalHeader(300)}>{intl.formatMessage({ id: "raster.details", defaultMessage: "Raster Details" })}</div>
        {selectedRaster && (
          <RasterEditForm
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            handleCancel={() => setSelectedRaster(undefined)}
            item={selectedRaster}
          />
        )}
      </div>
    </Drawer>
  );
};

export default RasterEditDrawer;