import { createPicklistTypes } from 'enums/createPicklistTypes'
import { PicklistItemModel } from 'data/models'
import { transform } from 'data/transform'
import { apiSettings } from 'etc/settings'
import { setCachedList } from 'reducers/cachedListReducer'
import store from 'utils/configureStore'
import { requestBuilder } from 'utils/request'
import { Dictionary } from 'types'
import { WaoSelectItem } from 'components/Forms.Interfaces'
import { IRootReduxState } from 'types/reducers'
import { useSelector } from 'react-redux'

const picklistTypes = createPicklistTypes().types

export interface CachedListContainer {
  data: any[]
  expires: number
}

interface CachedListOptions {
  name?: string
  url?: string
  ttl?: number
  map?: (item: any) => any
}

export const useCachedDepartments = () => _get('departments', useSelector((state: IRootReduxState) => state.app.cachedLists))
export const useCachedDivisions = () => _get('divisions', useSelector((state: IRootReduxState) => state.app.cachedLists))
export const useCachedJobClassifications = () => _get('jobClassifications', useSelector((state: IRootReduxState) => state.app.cachedLists))
export const useCachedJobClassificationsPicklistItems = () => _get('jobClassificationPicklistItems', useSelector((state: IRootReduxState) => state.app.cachedLists))
export const useCachedDivisionPicklistItems = () => _get('divisionPicklistItems', useSelector((state: IRootReduxState) => state.app.cachedLists))
export const useCachedDepartmentPicklistItems = () => _get('departmentPicklistItems', useSelector((state: IRootReduxState) => state.app.cachedLists))
export const useCachedClientPicklistItems = () => _get('clientPicklistItems', useSelector((state: IRootReduxState) => state.app.cachedLists))
export const useCachedLocationPicklistItems = () => _get('locationPicklistItems', useSelector((state: IRootReduxState) => state.app.cachedLists))
export const useCachedSubcontractorPicklistItems = () => _get('subcontractorPicklistItems', useSelector((state: IRootReduxState) => state.app.cachedLists))
export const useCachedShapeFileGroupsPicklistItems = () => _get('shapeFileGroupsPicklistItems', useSelector((state: IRootReduxState) => state.app.cachedLists))

const _get = (name: string, cachedLists: Dictionary<CachedListContainer>) => {
  return _createCachedList({
    name,
    url: _lists[name].url || '',
    map: _lists[name].map || undefined,
    ttl: 3600,
  }, cachedLists)
}

const _lists: Dictionary<CachedListOptions> = {
  departments: {
    url: `/odata/PicklistItems?$filter=Type eq ${picklistTypes.Department.value}`,
    map: (e) => mapToNameValue(e),
  },
  divisions: {
    url: `/odata/PicklistItems?$filter=Type eq ${picklistTypes.Division.value}`,
    map: (e) => mapToNameValue(e),
  },
  jobClassifications: {
    url: `/odata/PicklistItems?$filter=Type eq ${picklistTypes.JobClassification.value}`,
    map: (e) => mapToNameValue(e),
  },
  jobClassificationPicklistItems: {
    url: `/odata/PicklistItems?$filter=Type eq ${picklistTypes.JobClassification.value}`,
    map: (e) => mapToPicklistItem(e),
  },
  divisionPicklistItems: {
    url: `/odata/PicklistItems?$filter=Type eq ${picklistTypes.Division.value}`,
    map: (e) => mapToPicklistItem(e),
  },
  departmentPicklistItems: {
    url: `/odata/PicklistItems?$filter=Type eq ${picklistTypes.Department.value}`,
    map: (e) => mapToPicklistItem(e),
  },
  clientPicklistItems: {
    url: `/odata/PicklistItems?$filter=Type eq ${picklistTypes.Client.value}`,
    map: (e) => mapToPicklistItem(e),
  },
  locationPicklistItems: {
    url: `/odata/PicklistItems?$filter=Type eq ${picklistTypes.Location.value}`,
    map: (e) => mapToPicklistItem(e),
  },
  subcontractorPicklistItems: {
    url: `/odata/PicklistItems?$filter=Type eq ${picklistTypes.SubContractor.value}`,
    map: (e) => mapToPicklistItem(e),
  },
  shapeFileGroupsPicklistItems: {
    url: `/odata/PicklistItems?$filter=Type eq ${picklistTypes.ShapefileGroups.value}`,
    map: (e) => mapToPicklistItem(e),
  },
}

const mapToNameValue = (odata: any): WaoSelectItem => {
  let item = transform(PicklistItemModel).from(odata)
  return { name: item.getValue(), value: item.Id }
}

const mapToPicklistItem = (odata: any): PicklistItemModel => {
  return transform(PicklistItemModel).from(odata)
}

const _createCachedList = (options: CachedListOptions, cachedLists: Dictionary<CachedListContainer>): any[] => {
  const request = requestBuilder(apiSettings.workaware)
  const opts = Object.assign(
    {},
    {
      name: '',
      url: '',
      ttl: 3600,
      map: undefined,
    },
    options
  )

  const list = cachedLists[opts.name]

  if (list && +new Date() < list.expires) {
    return list.data
  }

  // set an empty list so we can return something while we fetch the data
  store.dispatch(
    setCachedList(opts.name, {
      data: [],
      expires: +new Date() + opts.ttl * 1000,
    })
  )

  request(opts.url).then((result) => {
    store.dispatch(
      setCachedList(opts.name, {
        data: opts.map ? result.value.map(opts.map) : result.value,
        expires: +new Date() + opts.ttl * 1000,
      })
    )
  })

  return cachedLists[opts.name]?.data ?? []
}
