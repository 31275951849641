
import { WorkawareBaseClass } from './WorkawareBaseClass'
export class EmailLogModel extends WorkawareBaseClass {
    _className = 'EmailLogModel'
    public Id: number
    public CompanyId: number
    public SentOn: Date
    public Subject: string | undefined = undefined
    public Content: string | undefined = undefined
    public From: string | undefined = undefined
    public To: string | undefined = undefined
    public BCC: string | undefined = undefined
    public SentSuccessfully?: boolean
    public IsContentHTML?: boolean
    public Exception: string | undefined = undefined
    public Tags: string[] = []

}