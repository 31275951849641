import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Button from 'components/Buttons'
import UserListItem from 'components/list-items/UserListItem'
import MenuToggleButton from 'components/MenuToggleButton'
import UserAvatar from 'components/UserAvatar'
import { useTeamsServices } from 'data/services'
import { locales } from 'etc/i18nConfig'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { setLocale, setTeamFilter, toggleTheme, toggleUseCache, setFormsFontSize } from 'reducers/userReducer'
import { IRootReduxState, IUserState } from 'types/reducers'
import userManager from 'utils/userManager'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { useIsMounted } from 'utils/utilities'
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  userName: {
    fontSize: '1rem',
    maxWidth: '140px',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}))

const logout = async () => {
  await userManager.signoutRedirect()
  await userManager.clearStaleState()
  await userManager.removeUser()
}

const defaultTeamFilterOption = {
  _id: '-1',
  title: <FormattedMessage id="profile-panel.no-team-filter" defaultMessage="No Filter" />,
}

interface Props {
  mobileScreen: Boolean
}
const ProfilePanel: React.FC<Props> = (props): JSX.Element => {
  const [teamOptions, setTeamOptions] = React.useState<{ _id: string; title: string | JSX.Element }[]>([defaultTeamFilterOption])
  const classes = useStyles()
  const userInfo = useSelector<IRootReduxState, IUserState>((state) => state.app.user)
  const dispatch = useDispatch()
  const teamServices = useTeamsServices()
  const isMounted = useIsMounted()
  const user = userInfo.current

  React.useEffect(() => {
    if (isMounted.current) {
      teamServices.myTeams().then((result) => {
        const teams = result.map((e) => ({ _id: `${e.Id}`, title: `${e.Name}` }))
        setTeamOptions([defaultTeamFilterOption, ...teams])
      })
    }
    // eslint-disable-next-line
  }, [])

  if (!user) return <React.Fragment />

  return (
    <Grid container direction={!props.mobileScreen ? 'row' : 'row-reverse'} justify={!props.mobileScreen ? 'center' : 'flex-end'} alignItems="center" wrap="nowrap">
      <Grid item>
        <Typography variant="h6" color="textPrimary" className={classes.userName}>
          {`${user.Lastname} ${user.Firstname}`}
        </Typography>
      </Grid>
      <Grid item>
        <MenuToggleButton content={<UserAvatar swapColours={false} username={user.Username} photoUrl={user.PhotoURL} />}>
          <Card className={classes.card}>
            <CardContent>
              <Grid>
                <Grid item xs={12}>
                  <UserListItem user={user} />
                </Grid>
                <Grid item xs={12}>
                
                  <FormControlLabel
                    control={<Switch checked={userInfo.theme !== 'light'} onChange={() => dispatch(toggleTheme())} color="primary" />}
                    label={<FormattedMessage id="profile-panel.use-dark-theme" defaultMessage="Use dark theme" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={12} alignItems="stretch">
                  <Typography id="discrete-slider">
                  <FormattedMessage id="form.fontsize" defaultMessage="Form Fontsize" />
                    </Typography>
                    <Slider
                      defaultValue={Math.round(100 / 14 * (userInfo.formsFontSize || 14))}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={10}
                      min={50}
                      max={150}
                      onChangeCommitted={(e: any, v: any) => {
                        const fontSize = 14 / 100 * v;
                        dispatch(setFormsFontSize(fontSize));
                      }}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Hidden implementation="css" smUp>
                    <InputLabel id="locale-select-label">
                      <FormattedMessage id="profile-panel.locale" defaultMessage="Language" />
                    </InputLabel>
                    <Select value={userInfo.localeCode} onChange={(e) => dispatch(setLocale(e.target.value))} fullWidth labelId="locale-select-label" id="locale-select">
                      {locales.map((l) => (
                        <MenuItem key={l.code} value={l.code}>
                          <ReactCountryFlag svg countryCode={l.flagCode} style={{ marginRight: 10 }} />
                          {l.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Hidden>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="team-filter-select-label">
                    <FormattedMessage id="profile-panel.team-filter" defaultMessage="Team Filter" />
                  </InputLabel>
                  <Select value={userInfo.teamFilter} onChange={(e) => dispatch(setTeamFilter(e.target.value))} fullWidth labelId="team-filter-select-label" id="team-filter-select">
                    {teamOptions.map((r) => (
                      <MenuItem key={r._id} value={r._id}>
                        {r.title}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel control={<Switch checked={userInfo.useCache !== false} onChange={() => dispatch(toggleUseCache())} color="primary" />} label={<FormattedMessage id="profile-panel.use-cache" defaultMessage="Use Cache" />} />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions disableSpacing>
              <FormGroup>
                <Button.Button click={logout} label={<FormattedMessage id="sign-out" defaultMessage="Sign Out" />} disableOnClick />
              </FormGroup>
            </CardActions>
          </Card>
        </MenuToggleButton>
      </Grid>
    </Grid>
  )
}

export default ProfilePanel
