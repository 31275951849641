import { getIntlObject } from 'etc/i18nConfig';
import { Dictionary } from 'types';
import { PickListTypeContainer } from 'data/services/picklistsService';

export const picklistTypes: Dictionary<{ value: number, nameId: string }> = {
  JobClassification: { value: 1, nameId: 'enums.picklist-types.job-classification' },
  Department: { value: 2, nameId: 'enums.picklist-types.department' },
  Division: { value: 3, nameId: 'enums.picklist-types.division' },
  Location: { value: 4, nameId: 'enums.picklist-types.location' },
  FormGroupName: { value: 5, nameId: 'enums.picklist-types.form-group-name' },
  Client: { value: 6, nameId: 'enums.picklist-types.client' },
  FormType: { value: 7, nameId: 'enums.picklist-types.form-type' },
  CMMSAssetEventTypes: { value: 8, nameId: 'enums.picklist-types.asset-event-types' },
  CMMSMeterReadingUnits: { value: 9, nameId: 'enums.picklist-types.meter-reading-units' },
  CMMSMaintenanceTypes: { value: 10, nameId: 'enums.picklist-types.maintenance-types' },
  CMMSPriorities: { value: 11, nameId: 'enums.picklist-types.priorities' },
  CMMSWorkOrderStatuses: { value: 12, nameId: 'enums.picklist-types.work-order-statuses' },
  CMMSReasonToSetAssetOffline: { value: 13, nameId: 'enums.picklist-types.reasons-to-set-asset-offline' },
  CMMSReasonToSetAssetOnline: { value: 14, nameId: 'enums.picklist-types.reasons-to-set-asset-online' },
  CMMSAssetCategoryTypes: { value: 15, nameId: 'enums.picklist-types.asset-categories' },
  CMMSDocumentTypes: { value: 16, nameId: 'enums.picklist-types.document-types' },
  CMMSMaintenanceTagoutHazards: { value: 17, nameId: 'enums.picklist-types.tagout-hazards' },
  CMMSMaintenanceTagoutTypes: { value: 18, nameId: 'enums.picklist-types.tagout-types' },
  SubContractor: { value: 19, nameId: 'enums.picklist-types.subcontractor' },
  GeospatialMarkers: { value: 20, nameId: 'enums.picklist-types.geospatial-markers' },
  ShapefileGroups: { value: 21, nameId: 'enums.picklist-types.shapefile-groups' },
  Users: { value: 22, nameId: 'enums.picklist-types.users' },
  FormSubmissionSources: { value: 23, nameId: 'enums.picklist-types.form-submission-sources' },
  FormPriorities: { value: 24, nameId: 'enums.picklist-types.form-priorities' },
  Genders: { value: 25, nameId: 'enums.picklist-types.genders' },
  OrgTeams: { value: 26, nameId: 'enums.picklist-types.organizational-teams' },
  UsersAndOrgTeams: { value: 27, nameId: 'enums.picklist-types.users-and-organizational-teams' },
  CompanyForms: { value: 28, nameId: 'enums.picklist-types.company-forms' },
  UserDefined: { value: 100, nameId: 'enums.picklist-types.user-defined' }
};

export const createPicklistTypes = () => {
  const intl = getIntlObject();
  const _types: Dictionary<PickListTypeContainer> = {};
  Object.keys(picklistTypes).forEach(key => {
    _types[key] = { value: picklistTypes[key].value, name: intl.formatMessage({ id: picklistTypes[key].nameId }), key: key }
  }
  );

  return {
    types: _types,

    defaultTypes: [
      _types.JobClassification,
      _types.Department,
      _types.Division,
      _types.FormGroupName,
      _types.Location,
      _types.FormType,
      _types.Client,
      _types.SubContractor,
      _types.GeospatialMarkers,
      _types.ShapefileGroups
    ],
  }
};
