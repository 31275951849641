import { WorkawareApi } from '../../apis/WorkawareApi'
import { TrackingModel } from '../models/TrackingModel'
import { transform, TransformMethods } from 'data/transform'
import { PagedResult } from "../../types/apis"

export class trackingService {
    private _api: WorkawareApi
    private _trackerTransform: TransformMethods<TrackingModel>
    constructor(api: WorkawareApi) {
        this._api = api
        this._trackerTransform = transform<TrackingModel>(TrackingModel)
    }
    get = (page: number, rowsPerPage: number) => new Promise<PagedResult<TrackingModel>>((resolve, reject) => {
        this._api.tracker.get(page, rowsPerPage)
            .then(result => resolve({
                Count: result['@odata.count'] as number,
                Values: this._trackerTransform.arrayFrom(result.value)
            }))
            .catch(error => reject(error))
    })
    create = (data: TrackingModel) => new Promise<TrackingModel>((resolve, reject) => {
        this._api.tracker.post(data)
            .then(result => resolve(this._trackerTransform.from(result)))
            .catch(error => reject(error))
    })
    update = (Id: number, data: TrackingModel) => new Promise<TrackingModel>((resolve, reject) => {
        this._api.tracker.put(Id, data)
            .then(result => resolve(this._trackerTransform.from(result)))
            .catch(error => reject(error))
    })
    delete = (Id: number) => {
        return new Promise<any>((resolve, reject) => {
            this._api.tracker.delete(Id)
                .then(result => resolve(result))
                .catch(error => reject(error))
        })
    }
}