export class UserGeneratedShapeModel {
  _className = 'UserGeneratedShapeModel'
  // mostly used for internal checks but very important to persist the values

  public id: string
  public Name?: string = undefined
  public Type: number = 0
  public Color?: string = undefined
  public Comments?: string = undefined
  public Points: {
    id: string
    Comments?: string
    Latitude: number
    Longitude: number
    RelatedFormId?: number
  }[] = []
  public CreatedByUserId?: number = 0
  public LengthMeters?: number = 0
  public AreaSquareMeters?: number = 0
  public RelatedFormId?: number = 0
  public CreatedByUserName?: string
  public IsDraft: boolean = false
}
