import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { ShapeFileModel } from 'data/models/ShapefileModel'
import React from 'react'
import ListItemBase from './ListItemBase'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'

interface Props {
  shapeFile: ShapeFileModel
  selected?: boolean
  multiSelect?: boolean
  handleSelect?: (ShapeFile: ShapeFileModel) => void
  showDetail?: boolean
}
const ShapeFilesListItem: React.FC<Props> = ({ shapeFile, selected, handleSelect, multiSelect = false }) => (
  <ListItemBase multiSelect={multiSelect} handleClick={() => handleSelect && handleSelect(shapeFile)} selected={selected}>
    <ListItem
      onClick={(e) => {
        e.preventDefault()
        handleSelect && handleSelect(shapeFile)
      }}
    >
   <ListItemAvatar>
      <DynamicFeedIcon color={shapeFile.IsActive ? 'secondary' : 'disabled'} />
      </ListItemAvatar>
      <ListItemText primary={shapeFile.Name} color={shapeFile.IsActive ? 'secondary' : 'disabled'} />
    </ListItem>
  </ListItemBase>
)
export default ShapeFilesListItem
