

export enum Module {
    ParticipationDashboard = 'ParticipationDashboard',
    Dashboard = 'Dashboard',
    Calendar = 'Calendar',
    ScheduledItems = 'ScheduledItems',
    IncidentInvestigation = 'IncidentInvestigation',
    DocumentLibrary = 'DocumentLibrary',
    CameraEvents = 'CameraEvents',
    ManualEntries = 'ManualEntries',
    BlankForms = 'BlankForms',
    CompletedForms = 'CompletedForms',
    FormsPendingUpload = 'FormsPendingUpload',
    FormsSearch = 'FormsSearch',
    People = 'People',
    OrganizationChart = 'OrganizationChart',
    LocationTracking = 'LocationTracking',
    JourneyManagement = 'JourneyManagement',
    TrainingMatrix = 'TrainingMatrix',
    OrientationAndCourses = 'OrientationAndCourses',
    CMMSAssets = 'CMMSAssets',
    CMMSMaintenanceDashboard = 'CMMSMaintenanceDashboard',
    InteractiveCharts = 'InteractiveCharts',
    ComputerSettings = 'ComputerSettings',
    CompanySettings = 'CompanySettings',
    Logout = 'Logout',
    Exit = 'Exit',
    Geospatial = 'Geospatial',
    Reports = 'Reports',
    DirectMessages = 'DirectMessages',
    Assignments = 'Assignments',
    LMSCourses = 'LMSCourses',
    CompanyMessage = 'CompanyMessage',
    CustomScreens = 'CustomScreens' ,
    UserCourses = "UserCourses"  
}