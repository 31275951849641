import { WorkawareBaseClass } from './WorkawareBaseClass'


export class TrackingModel extends WorkawareBaseClass {
    _className = "TrackingModel"

    public Id: number
    public CompanyId: number| undefined = undefined
    public Type: number | undefined = undefined
    public Username: string | undefined = undefined
    public Password: string | undefined = undefined
    public FeedId: string | undefined = undefined
    public Name: string | undefined = undefined
}