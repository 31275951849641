import { WorkawareBaseClass } from "data/models/WorkawareBaseClass";
import { AcknowledgeableDocumentsSummaryDto } from "./AcknowledgeableDocumentsSummaryDto";
import { CertificatesSummaryDto } from "./CertificatesSummaryDto";
import { LMSCourseSummaryDto } from "./LMSCourseSummaryDto";
import { UserTrainingProfileDto } from "./UserTrainingProfileDto";

export class TrainingSummaryDto extends WorkawareBaseClass {
    public ActiveUserProfiles: UserTrainingProfileDto[] = []
    public Policies: AcknowledgeableDocumentsSummaryDto[] = []
    public Courses: LMSCourseSummaryDto[] = []
    public Certificates: CertificatesSummaryDto[] = []
}