import { WorkawareBaseClass } from './WorkawareBaseClass'
export class ShapeFileModel extends WorkawareBaseClass {
  _className = 'ShapeFileModel'
  public Id: number
  public CompanyId: number | undefined = undefined
  public Name: string | undefined = undefined
  public Description: string | undefined
  public URL: string | undefined = undefined
  public Filename: string | undefined = undefined
  public DateUploaded: Date | undefined = new Date()
  public MinimumZoomVisible: number | undefined = undefined
  public MaximumZoomVisible: number | undefined = undefined
  public IsActive: boolean = false
  public Color: string | undefined = undefined
  public DisplayOrder: number | undefined = undefined
  public HasDBF: boolean | undefined = undefined
  public HasPRJ: boolean | undefined = undefined
  public HasSHX: boolean | undefined = undefined
  public HasSQLiteDB: boolean | undefined = undefined
  public Type: number | undefined = undefined
  public ToolTipFormat: string | undefined = undefined
  public DefaultVisibleOnMap: boolean | undefined = undefined
  public HasPointPNGIcon: boolean | undefined = undefined
  public StrokeThickness: number | undefined = undefined
  public ClusteringEnabled: boolean | undefined = undefined
  public GroupNamePicklistId: number | undefined = undefined
  public BackgroundColor: string | undefined
  public BrushStyleId: number | undefined = undefined
  public IdField: string | undefined
  public StyleTypeId: number | undefined = undefined
  public StyleCompanyFormId: number | undefined = undefined
  public CompanyFormPolygonIdField: string | undefined
  public CompanyFormColorField: string | undefined
  public CompanyFormColors: string | undefined
  public LineBufferInMeters: number | undefined = undefined
  public LockToCompanyFormId: number | undefined = undefined
  public Filesize: number | undefined = undefined
  public CompanyFormPolygonIdFieldExpression: string | undefined
  public ViewDynamicExpression: string | undefined
}
