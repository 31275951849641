import { SMSMessageDto } from 'data/dtos/SMSMessageDto';
import { DirectMessageModel } from 'data/models/DirectMessageModel';
import { WorkawareApi } from '../../apis/WorkawareApi'
import { DirectMessageLogEntryDto } from '../../data/dtos/DirectMessageLogEntryDto';
import { PagedResult } from "../../types/apis"
export class directMessageServie {
   private _api: WorkawareApi
    constructor(api:WorkawareApi){
        this._api = api
    }

    get =(page: number, rowsPerPage: number) => new Promise<PagedResult<DirectMessageLogEntryDto>>((resulve,reject) =>{
        this._api.directMessage.getLog(page,rowsPerPage)
        .then(result => resulve({
            Count: result["Count"] as number,   
            Values:result.Values
        }))
        .catch(error => reject(error))
    })
    addMessage=(data:DirectMessageModel) => new Promise<DirectMessageModel>((resolve,reject) =>{
        this._api.directMessage.post(data)
        .then(result => resolve(result))
        .catch(error => reject(error))
    })
    sendMessage=(data:SMSMessageDto) => new Promise<SMSMessageDto>((resolve,reject) =>{
        this._api.directMessage.smsPost(data)
        .then(result => resolve(result))
        .catch(error => reject(error))
    })
}