import { CourseModel } from "data/models/CourseModel"
import {WorkawareApi} from "../../apis/WorkawareApi"
import { transform, TransformMethods } from 'data/transform'

export class courseService {

    private _api : WorkawareApi
    private _courseTransform: TransformMethods<CourseModel>
    constructor(api : WorkawareApi){
        this._api = api
        this._courseTransform = transform<CourseModel>(CourseModel)
    }
    runReminder = () =>{
        return new Promise<any>((resolve,reject) =>{
            this._api.courses.reminder()
            .then(result => resolve(result))
            .catch(error => reject(error))
        })
    }
    getList = () =>{
        return new Promise<CourseModel[]>((resolve,reject) =>{
            this._api.courses.get()
            .then(result => resolve(this._courseTransform.arrayFrom(result.value)))
            .catch(error => reject(error))
        })
    }
    postCourse = (data: CourseModel) =>{
        return new Promise<CourseModel>((resolve,reject) =>{
            this._api.courses.post(data)
            .then(result => resolve(this._courseTransform.from(result)))
            .catch(error => reject(error))
        })
    }
    putCourse = (key: number, data: CourseModel)=>{
        return new Promise<CourseModel>((resolve,reject) =>{
            this._api.courses.put(key,data)
            .then(result => resolve(this._courseTransform.from(result)))
            .catch(error => reject(error))
        })
    }
    deleteCourse = (key:number) =>{
        return new Promise<any>((resolve,reject) =>{
            this._api.courses.delete(key)
            .then(result => resolve(result))
            .catch(error => reject(error))
        })
    }
}