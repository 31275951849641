import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../components/Buttons';
import React from 'react';
import { CompanyMessageModel } from '../../data/models/CompanyMessageModel';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { longDate, parseColour } from '../../utils/utilities'
import MessageIcon from '@material-ui/icons/Message'
import ListItemBase from '../../components/list-items/ListItemBase';
import { FormattedMessage } from 'react-intl'

interface Props  {
    open : boolean,
    item? : CompanyMessageModel,
    handleClose?: ()=> void
}

const CompanyMessageDialog: React.FC<Props> = ({open, item,handleClose}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
      <FormattedMessage id="companyMessage.title" defaultMessage="Company Message"/> <br></br>
      <ListItemBase>
        <ListItem>
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: parseColour(item?.Message) }}>
                    {<MessageIcon/>}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={<FormattedMessage id="companyMessage.username" defaultMessage="Message from {name}" values={{name:item?.FirstName + ' '+ item?.LastName}}/>} secondary={<FormattedMessage id="companyMessage.createdOn" defaultMessage="Created on {date}" values={{date:longDate(item?.CreatedOn)}} />}/>
        </ListItem>
    </ListItemBase>
      </DialogTitle>
      <DialogContent>
      <span dangerouslySetInnerHTML={{__html: item?.Message || ''}} />
      </DialogContent>
      <DialogActions>
      <Button.OkButton click={(e) => {handleClose && handleClose()}} />
      </DialogActions>
    </Dialog>
  );
};

export default CompanyMessageDialog;
