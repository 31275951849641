import { WorkawareApi } from '../../apis/WorkawareApi'
import {CompanyMessageModel} from 'data/models/CompanyMessageModel'

export class companyMessagesService{
    private _api: WorkawareApi
    constructor(api:WorkawareApi){
        this._api = api
    }

    getAllMessages =() => new Promise<CompanyMessageModel[]>((resolve,reject) =>{
            this._api.companyMessage.getList()
            .then(result => resolve(result))
            .catch(error => reject(error))
        })
    putMessage = (data: CompanyMessageModel) => new Promise<any>((resolve,reject) =>{
        this._api.companyMessage.put(data)
        .then(result => resolve(result))
        .catch(error => reject(error))
    })
    postMessages = (data: CompanyMessageModel) => new Promise<any>((resolve,reject) =>{
        this._api.companyMessage.post(data)
        .then(result => resolve(result))
        .catch(error => reject(error))
    })
    deleteMessage =(id:number) => 
    new Promise<any>((resolve,rejects) =>{
        this._api.companyMessage.delete(id)
        .then(result => resolve(result))
        .catch(error => rejects(error))
    })
}