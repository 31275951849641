import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CssBaseline from '@material-ui/core/CssBaseline'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn'
import BusinessIcon from '@material-ui/icons/Business'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ListIcon from '@material-ui/icons/List'
import PeopleIcon from '@material-ui/icons/People'
import Security from '@material-ui/icons/Security'
import SettingsIcon from '@material-ui/icons/Settings'
import ApartmentIcon from '@material-ui/icons/Apartment'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import ScheduleIcon from '@material-ui/icons/Schedule'
import clsx from 'clsx'
import { CounterIcon, AssignmentsIcon, FormsIcon, HomeIcon, LibraryIcon, OrganizationIcon, ReportsIcon, RasterImageIcon, JourneyIcons, LocationTrackingIcon, DirectMessage } from 'components/SvgIcons'
import { uiSettings } from 'etc/settings'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRootReduxState } from 'types/reducers'
import MenuListItem from './MenuListItem'
import MenuListItemContainer from './MenuListItemContainer'
import SideNavigation from './SideNavigation'
import EventIcon from '@material-ui/icons/Event'
import TopNavigation from './TopNavigation'
import { CameraEnhanceOutlined, FormatShapes } from '@material-ui/icons'
import { CompanyModuleAccessDto } from 'data/dtos/CompanyModuleAccessDto'
import SecuredModule from '../../components/navigation/SecuredModule'
import { Module } from 'enums/Module'
import MessageIcon from '@material-ui/icons/Message'
import CoursesIcon from '@material-ui/icons/InsertDriveFile'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined'
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import Tracker from '@material-ui/icons/TrackChangesOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { TrainingMatrixIcon, CertificateIcon, PolicyIcon, PrivateCourseIcon } from 'components/SvgIcons'
import RecurringAssignment from '@material-ui/icons/Assignment';
const useStyles = makeStyles((theme) => ({
  root: (props: any) => ({
    display: 'flex',
    position: 'relative',
    minHeight: '100%',
    background: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
    paddingTop: !props.profile ? 0 : props.bigScreen ? uiSettings.header.appBarHeight : uiSettings.header.appBarHeightMobile,
  }),
  hide: {
    visibility: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: 0,
    minWidth: 0,
  },
  paddingY0: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  w1em: {
    width: '1em !important',
  },
  w110em: {
    width: '1.10em !important',
  },
  ml3: {
    marginLeft: '3px',
  },
}))

interface Props {
  showTopNavigation?: boolean
}
const Navigation: React.FC<Props> = ({ children, showTopNavigation = true }) => {
  const bigScreen = useMediaQuery('(min-width:601px)')
  const profile = useSelector((state: IRootReduxState) => state.app.user.current)
  const moduleAccess = useSelector((state: IRootReduxState) => state.app.user.access)
  const classes = useStyles({ bigScreen, profile })

  const getModule = (module: Module) => {
    const permissions = moduleAccess?.find((i) => i.Module === module) as CompanyModuleAccessDto
    return permissions
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {profile && showTopNavigation && <TopNavigation />}
      {profile && (
        <SideNavigation>
          <List>
            <MenuListItem title={<FormattedMessage id="menu.main.home" defaultMessage="Home" />} icon={<HomeIcon />} route="/" />

            <SecuredModule permissions={getModule(Module.Reports)}>
              <MenuListItemContainer title={<FormattedMessage id="menu.main.reports" defaultMessage="Reports" />} icon={<ReportsIcon />}>
                <List className={classes.paddingY0}>
                  <MenuListItem title={<FormattedMessage id="menu.main.live" defaultMessage="Live" />} icon={<DashboardIcon />} route="/reports/live" />
                  <MenuListItem title={<FormattedMessage id="menu.main.participation" defaultMessage="Participation" />} icon={<DashboardIcon />} route="/reports/participation" />
                  <MenuListItem title={<FormattedMessage id="menu.main.maintenance" defaultMessage="Asset Maintenance" />} icon={<DashboardIcon />} route="/reports/maintenance" />
                </List>
              </MenuListItemContainer>
            </SecuredModule>
            <SecuredModule permissions={getModule(Module.OrganizationChart)}>
              <MenuListItem title={<FormattedMessage id="menu.main.organization" defaultMessage="Organization" />} icon={<OrganizationIcon />} route="/organization" />
            </SecuredModule>

            <SecuredModule permissions={getModule(Module.Calendar)}>
              <MenuListItem title={<FormattedMessage id="menu.main.calendar" defaultMessage="Calendar" />} icon={<EventIcon />} route="/calendar" />
            </SecuredModule>

            <SecuredModule permissions={getModule(Module.JourneyManagement)}>
              <MenuListItem title={<FormattedMessage id="menu.main.journey" defaultMessage="Journey Management" />} icon={<JourneyIcons />} route="/journeyManagement" />
            </SecuredModule>

            <MenuListItem title={<FormattedMessage id="menu.main.courses" defaultMessage="Courses" />} icon={<CoursesIcon />} route="/courses" />
            <SecuredModule permissions={getModule(Module.DocumentLibrary)}>
              <MenuListItemContainer title={<FormattedMessage id="menu.main.library" defaultMessage="Library" />} icon={<LibraryIcon />}>
                <List className={classes.paddingY0}>
                  <MenuListItem title={<FormattedMessage id="menu.main.Documents" defaultMessage="Documents" />} icon={<FontAwesomeIcon icon="book" />} route="/library/documents" />
                  <MenuListItem title={<FormattedMessage id="menu.main.culture-groups" defaultMessage="Culture Groups" />} icon={<FontAwesomeIcon icon="globe-americas" />} route="/library/cultureGroups" />
                </List>
              </MenuListItemContainer>
            </SecuredModule>

            <MenuListItemContainer title={<FormattedMessage id="menu.main.reports" defaultMessage="Training Matrix" />} icon={<TrainingMatrixIcon />}>
              <List className={classes.paddingY0}>
                <MenuListItem title={<FormattedMessage id="menu.main.certificates" defaultMessage="Certificates" />} icon={<CertificateIcon />} route="/trainingmatirx/usercertificates" />
                <MenuListItem title={<FormattedMessage id="menu.main.policies" defaultMessage="User Policies" />} icon={<PolicyIcon />} route="/trainingmatirx/policies" />
                <MenuListItem title={<FormattedMessage id="menu.main.privatecoures" defaultMessage="Private Courses" />} icon={<PrivateCourseIcon />} route="/trainingmatirx/Privatecourses" />
              </List>
            </MenuListItemContainer>

            <MenuListItemContainer title={<FormattedMessage id="menu.main.forms" defaultMessage="Forms" />} icon={<FormsIcon />}>
              <List className={classes.paddingY0}>
                <SecuredModule permissions={getModule(Module.CompletedForms)}>
                  <MenuListItem title={<FormattedMessage id="menu.main.forms.completed" defaultMessage="Completed" />} icon={<FontAwesomeIcon icon="file-alt" className={classes.w1em} />} route="/forms/completed" />
                </SecuredModule>
                <SecuredModule permissions={getModule(Module.BlankForms)}>
                  <>
                    <MenuListItem title={<FormattedMessage id="menu.main.forms.templates" defaultMessage="Templates" />} icon={<FontAwesomeIcon icon="file-invoice" className={classes.w1em} />} route="/form/templates" />
                    <MenuListItem
                      title={<FormattedMessage id="menu.main.forms.anonymous" defaultMessage="Anonymous" />}
                      icon={<FontAwesomeIcon icon="file-signature" className={clsx([classes.ml3, classes.w1em])} />}
                      route="/form/templates/anonymous"
                    />
                  </>
                </SecuredModule>
              </List>
            </MenuListItemContainer>
            <SecuredModule permissions={getModule(Module.Assignments)}>
              <MenuListItemContainer title={<FormattedMessage id="menu.main.assignments.header" defaultMessage="Assignments" />} icon={<AssignmentsIcon />}>
                <List className={classes.paddingY0}>
                  <MenuListItem title={<FormattedMessage id="menu.main.assignments.you" defaultMessage="Assigned To You" />} icon={<AssignmentIndIcon />} route="/assignments/assignedToYou" />
                  <MenuListItem title={<FormattedMessage id="menu.main.assignments.other" defaultMessage="Assigned To Others" />} icon={<AssignmentReturnIcon />} route="/assignments/assignedByYou" />
                  <MenuListItem title={<FormattedMessage id="menu.main.assignment.recurring" defaultMessage="Recurring Assignment" />} icon={<RecurringAssignment/>} route="/assignment/recurring" />
                </List>
              </MenuListItemContainer>
            </SecuredModule>

            <SecuredModule permissions={getModule(Module.CMMSAssets)}>
              <MenuListItemContainer title={<FormattedMessage id="menu.main.assets.header" defaultMessage="Assets" />} icon={<ApartmentIcon />}>
                <List className={classes.paddingY0}>
                  <MenuListItem title={<FormattedMessage id="menu.main.sites.locations.you" defaultMessage="Sites & Locations" />} icon={<LocationCityIcon />} route="/assets/sitesLocations" />
                  <MenuListItem title={<FormattedMessage id="menu.main.picklists" defaultMessage="Picklists" />} icon={<ListIcon />} route="/assets/picklists" />
                  <MenuListItem title={<FormattedMessage id="menu.main.maintenance.schedules" defaultMessage="Maintenance Schedules" />} icon={<ScheduleIcon />} route="/assets/maintenanceSchedules" />
                  <MenuListItem title={<FormattedMessage id="menu.main.assets" defaultMessage="Assets" />} icon={<ApartmentIcon />} route="/assets/assetsmanagement" />
                </List>
              </MenuListItemContainer>
            </SecuredModule>
            <SecuredModule permissions={getModule(Module.CompanySettings)}>
              <MenuListItemContainer title={<FormattedMessage id="menu.main.settings.header" defaultMessage="Settings" />} icon={<SettingsIcon />}>
                <List className={classes.paddingY0}>
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.company" defaultMessage="Company" />} icon={<BusinessIcon classes={{ root: classes.w110em }} />} route="/settings/company" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.picklists" defaultMessage="PickLists" />} icon={<ListIcon className={classes.w110em} />} route="/settings/picklist" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.rasters" defaultMessage="Rasters" />} icon={<RasterImageIcon className={classes.w110em} />} route="/settings/raster" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.users" defaultMessage="Users" />} icon={<PeopleIcon className={classes.w110em} />} route="/settings/users" />
                  <MenuListItem title={<FormattedMessage id="menu.main.setting.directMessage" defaultMessage="Direct Message" />} icon={<DirectMessage />} route="/setting/directMessage" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.EmailTemplate" defaultMessage="Email Templates" />} icon={<FontAwesomeIcon icon="file-invoice" className={classes.w110em} />} route="/settings/emailTemplates" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.CompanyMessages" defaultMessage="Company Message" />} icon={<MessageIcon className={classes.w110em} />} route="/settings/companyMessages" />
                  <MenuListItem title={<FormattedMessage id="menu.main.setting.Courses" defaultMessage="Courses" />} icon={<CoursesIcon className={classes.w110em} />} route="/settings/courses" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.ShapeFiles" defaultMessage="Shape Files" />} icon={<FormatShapes className={classes.w110em} />} route="/settings/shapeFiles" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.UserGeneratedShapes" defaultMessage="User Generated Shapes" />} icon={<FormatShapes className={classes.w110em} />} route="/settings/UserGeneratedShapes" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.security" defaultMessage="Security" />} icon={<Security className={classes.w110em} />} route="/settings/securityModules" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.counter" defaultMessage="Counters" />} icon={<CounterIcon />} route="/settings/counters" />
                  <MenuListItem title={<FormattedMessage id="menu.main.setting.EmailLog" defaultMessage="Email Log" />} icon={<MailOutlineIcon className={classes.w110em} />} route="/setting/emailLog" />
                  <MenuListItem title={<FormattedMessage id="menu.main.setting.Events" defaultMessage="Events" />} icon={<EventNoteOutlinedIcon className={classes.w110em} />} route="/setting/events" />
                  <MenuListItem title={<FormattedMessage id="menu.main.setting.tracker" defaultMessage="Tracker" />} icon={<Tracker className={classes.w110em} />} route="/setting/tracker" />
                  <MenuListItem title={<FormattedMessage id="menu.main.setting.CameraSettings" defaultMessage="CameraSettings" />} icon={<CameraEnhanceOutlined className={classes.w110em} />} route="/setting/camerasettings" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.SMSLog" defaultMessage="SMS Log" />} icon={<SmsOutlinedIcon className={classes.w110em} />} route="/settings/smsLog" />
                  <MenuListItem title={<FormattedMessage id="menu.main.settings.AssignmentStatus" defaultMessage="Assignment Status" />} icon={<AssignmentOutlinedIcon className={classes.w110em} />} route="/settings/assignmentStatus" />
                </List>
              </MenuListItemContainer>
            </SecuredModule>
          </List>
          <MenuListItem title={<FormattedMessage id="menu.main.location.tracking" defaultMessage="Location Tracking" />} icon={<LocationTrackingIcon className={classes.w110em} />} route="/locationTracking" />
        </SideNavigation>
      )}
      <main className={classes.content}>{children}</main>
    </div>
  )
}

export default Navigation
