import { WorkawareApi } from 'apis/WorkawareApi'
import { TrainingSummaryDto } from 'data/dtos/TrainingSummaryDTO\'s/TrainingSummaryDto'
import { transform, TransformMethods } from 'data/transform'


export class trainingMatrixService {
    private _api: WorkawareApi
    private _trainingMatrixTransform: TransformMethods<TrainingSummaryDto>

    constructor(api: WorkawareApi) {
        this._api = api
        this._trainingMatrixTransform = transform<TrainingSummaryDto>(TrainingSummaryDto)
    }
    getSummarys = () => {
        return new Promise<any>((resolve, reject) => {
            this._api.trainingMatrix
                .getSummary()
                .then((result) => resolve(result))
                .catch((error) => reject(error))
        })
    }
}