import { WorkawareApi } from 'apis/WorkawareApi'
import { ShapeFileModel } from 'data/models/ShapefileModel'
import { transform, TransformMethods } from 'data/transform'
import { copyProperties } from 'utils/utilities'

export class shapeFilesService {
  private _api: WorkawareApi
  private _shapeTransform: TransformMethods<ShapeFileModel>

  constructor(api: WorkawareApi) {
    this._api = api
    this._shapeTransform = transform<ShapeFileModel>(ShapeFileModel)
  }

  getShapeFile = async () => {
    const result = await this._api.shapeFiles.all()
    return this._shapeTransform.arrayFrom(result.value)
  }
  addShape = async (data: ShapeFileModel) => {  
    const result = await this._api.shapeFiles.add(data)
    return copyProperties(new ShapeFileModel(), result)
  }

  updateShape = async (id: number, modifications: any) => {
    const result = await this._api.shapeFiles.update(id, modifications)
    return copyProperties(new ShapeFileModel(), result)
  }

  deleteShape = async (id: number) => {
    await this._api.shapeFiles.delete(id)
  }
}
