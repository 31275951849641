import { PagedResult } from "../../types/apis"
import { WorkawareApi } from '../../apis/WorkawareApi'
import { SMSLogModel } from '../models/SMSLogModel'

export class smsLogService {
    private _api: WorkawareApi
    constructor(api: WorkawareApi) {
        this._api = api
    }

    get = (page: number, rowsPerPage: number) => {
        return new Promise<PagedResult<SMSLogModel>>((resolve, reject) => {
            this._api.smsLog.get(page, rowsPerPage)
                .then(result => resolve({
                    Count: result["Count"] as number,
                    Values: result.Values
                }))
                .catch(error => reject(error))
        })
    }
}