import { Accordion, AccordionDetails, AccordionSummary, Grid, Radio, RadioProps } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import clsx from 'clsx'
import moment from 'moment'
import React from 'react'
import { CompactPicker } from 'react-color'
import { FormattedMessage } from 'react-intl'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { IRootReduxState } from 'types/reducers'
import { WaoCheckboxProps, WaoDateTimeProps, WaoExpenderProps, WaoFormContainerProps, WaoMenuProps, WaoNumberFormatProps, WaoPhoneNumberProps, WaoSelectProps, WaoSliderProps, WaoStandardProps, WaoTextAreaProps,  WaoTimePickerProps } from './Forms.Interfaces'
import { KeyboardTimePicker,MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    panelHeading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    logo: {
      maxWidth: 150,
      maxHeight: 150,
    },
    header: {
      fontSize: '1.75em',
      fontWeight: 500,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
    margins: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
    inlineMargins: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: 'inline-block',
    },
    colorPickerSwatch: {
      width: '14px',
      height: '14px',
      borderRadius: '2px',
    },
    swatch: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      verticalAlign: 'bottom',
    },
    wideLabel: {
      display: 'inline-flex',
      width: '100%',
    },
    popover: {
      position: 'absolute',
      zIndex: 2,
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
      background: '#666',
      opacity: 0.666,
    },
    menu: {
      float: 'right',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
})

export const WaoFormContainer = ({ children, submit }: WaoFormContainerProps) => {
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        event.stopPropagation()
        submit && submit(event)
        return false
      }}
    >
      {children}
    </form>
  )
}

export const WaoEmptyListPlaceholder = () => {
  const loading = useSelector((state: IRootReduxState) => state.app.state.loading)
  return (
    <div style={{ fontStyle: 'oblique', margin: '40px 20px' }}>
      {loading === 0 && <FormattedMessage id="empty-list" defaultMessage="Empty list. No results returned." />}
      {loading > 0 && <FormattedMessage id="loading" defaultMessage="loading..." />}
    </div>
  )
}

export const WaoExpander = ({ label, children, expanded = false, disabled = false }: WaoExpenderProps) => {
  const classes = useStyles()

  return (
    <Accordion defaultExpanded={expanded} disabled={disabled}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.panelHeading}>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export const WaoHeader = ({ label }: WaoStandardProps) => {
  const classes = useStyles()
  return (
    <Typography variant="h4" className={classes.header}>
      {label}
    </Typography>
  )
}

export const WaoSubHeader = ({ label }: WaoStandardProps) => {
  const classes = useStyles()
  return (
    <Typography className={classes.margins} variant="subtitle1">
      {label}
    </Typography>
  )
}

export const WaoIntegerNumberFormat: React.FC<WaoNumberFormatProps> = ({ inputRef, onChange, ...props }: WaoNumberFormatProps) => {
  return (
    <NumberFormat
      {...props}
      decimalScale={0}
      isNumericString={true}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange && onChange({ target: { name: props.id, value: values.value } })
      }}
    />
  )
}

export const WaoPositiveIntegerNumberFormat: React.FC<WaoNumberFormatProps> = (props: WaoNumberFormatProps) => {
  return <WaoIntegerNumberFormat {...props} allowNegative={false} />
}
export const WaoPhoneNumber: React.FC<WaoPhoneNumberProps> = ({name, value, handleChange, handleBlur, label, ...props}:WaoPhoneNumberProps) =>{
  const classes = useStyles()
  return(
    <MuiPhoneNumber
     className={classes.margins}
      fullWidth
      id={name}
      label={label || name}
      value={value || ''}
      onChange={(event) => {
        event.stopPropagation && event.stopPropagation()
        handleChange?.(name)(event)
      }}
      onBlur={(event) => {
        handleBlur?.(name)(event)
      }}
    />
  )
}
export const WaoText: React.FC<WaoStandardProps> = ({ name, value, handleChange, handleBlur, label, ...props }: WaoStandardProps) => {
  const classes = useStyles()
  const { defaultValue, ...inputProps } = props

  return (
    <TextField
      {...inputProps}
      className={classes.margins}
      fullWidth
      id={name}
      label={label || name}
      value={value || ''}
      onMouseDown={(event) => event.stopPropagation()}
      onChange={(event) => {
        event.stopPropagation && event.stopPropagation()
        handleChange?.(name)(event.target.value)
      }}
      onBlur={(event) => {
        handleBlur?.(name)(event.target.value)
      }}
    />
  )
}

export const WaoPositiveIntegerInput: React.FC<WaoStandardProps> = (props: WaoStandardProps) => {
  return <WaoText {...props} InputProps={{ inputComponent: WaoPositiveIntegerNumberFormat as any }} />
}

export const WaoIntegerInput: React.FC<WaoStandardProps> = (props: WaoStandardProps) => {
  return <WaoText {...props} InputProps={{ inputComponent: WaoIntegerNumberFormat as any }} />
}

export const WaoTextArea = ({ name, value, handleChange, label }: WaoTextAreaProps) => {
  const classes = useStyles()
  return <FormControlLabel label={label || name} control={<TextareaAutosize className={classes.margins} id={name} value={value} onChange={(event) => handleChange && handleChange(name)(event.target.value)} />} />
}

interface WaoRadioProps {
  radioValue: any
  radioLabel: string | JSX.Element
}
export const WaoRadio = (props: WaoRadioProps & RadioProps) => {
  const classes = useStyles()
  const { radioValue, radioLabel, ...theRestOfTheProps } = props
  return <FormControlLabel classes={{ root: classes.wideLabel, label: classes.wideLabel }} className={classes.margins} value={radioValue} control={<Radio {...theRestOfTheProps} />} label={radioLabel} />
}

export const WaoCheckbox = ({ label, name, value, handleChange, disabled = false }: WaoCheckboxProps) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      classes={{ root: clsx(classes.margins, classes.wideLabel), label: classes.wideLabel }}
      label={label || name}
      control={
        <Checkbox
          disabled={disabled}
          checked={value || false}
          onChange={(event) => {
            handleChange && handleChange(name)(event.target.checked)
          }}
          name={name}
        />
      }
    />
  )
}

export const WaoDate = ({ name, value, handleChange, label = undefined, disabled = false }: WaoStandardProps) => {
  const classes = useStyles()
  return (
    <TextField
      className={classes.margins}
      fullWidth
      disabled={disabled}
      type="date"
      onChange={(event) => {
        handleChange && handleChange(name)(event.target.value)
      }}
      InputLabelProps={{ shrink: true }}
      id={name}
      label={label || name}
      value={_toHtml5DateOnly(value?.toString())}
    />
  )
}

export const WaoDateTime = ({ name, value, handleChange, label = undefined, disabled = false }: WaoDateTimeProps) => {
  const classes = useStyles()
  return (
    <TextField
      className={classes.margins}
      fullWidth
      disabled={disabled}
      type="datetime-local"
      onChange={(event) => {
        handleChange && handleChange(name)(event.target.value)
      }}
      InputLabelProps={{ shrink: true }}
      id={name}
      label={label || name}
      value={_toHtml5Date(value)}
    />
  )
}
export default function WaoTimePicker({name, value, handleChange, label, disabled = false}:WaoTimePickerProps) {
  const classes = useStyles();
  return (
     <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label={label}
              value={value}
              onChange={(event) => {
              handleChange&& handleChange(name)(event?event:new Date())}}
              KeyboardButtonProps={{
              'aria-label': 'change time',
              }}
              
            />
        </MuiPickersUtilsProvider>
  );
}

export const WaoSelect = ({ name, items, value, handleChange, label, ...props }: WaoSelectProps) => {
  const classes = useStyles()
  const inputLabel = React.useRef<HTMLLabelElement>(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth)
    }
  }, [])
  return (
    <FormControl fullWidth className={classes.margins}>
      <InputLabel style={{ whiteSpace: 'nowrap' }} ref={inputLabel} htmlFor={name}>
        {label || name}
      </InputLabel>
      <Select
        {...props}
        style={{ minWidth: labelWidth + 30 }}
        value={value !== undefined ? value : ''}
        onChange={(event) => {
          handleChange && handleChange(name)(event.target.value)
        }}
        inputProps={{ name, id: name }}
      >
        {items.map((r) => (
          <MenuItem key={r.value} value={r.value}>
            {r.name}
            {r.detail && <br />}
            {r.detail}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const WaoSlider = ({ name, label, value = 0, min = 0, max = 20, step = 1, handleChange }: WaoSliderProps) => {
  const classes = useStyles()
  return (
    <div className={classes.margins}>
      <Typography variant="caption" gutterBottom>
        {label || name}
      </Typography>
      <Slider
        name={name}
        defaultValue={value}
        getAriaValueText={(v) => v.toString()}
        min={min}
        max={max}
        step={step}
        onChange={(event, value) => {
          event.stopPropagation()
          handleChange && handleChange(name)(value)
        }}
        valueLabelDisplay="auto"
      />
    </div>
  )
}

export const WaoColourPicker = ({ name, label, value, handleChange }: WaoStandardProps) => {
  const classes = useStyles()
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false)
  const changeByTextField = (value: string) => {
    // make sure the string starts with an octothorpe
    if (value.length > 0 && value[0] !== '#') value = '#' + value
    // hex values are 7 characters long
    if (value.length > 7) value = value.substring(0, 7)
    handleChange && handleChange(name)(value)
  }
  return (
    <div>
      <TextField className={classes.inlineMargins} id={name} label={label || name} value={value || ''} onChange={(event) => changeByTextField(event.target.value)} />
      <div className={classes.swatch} onClick={() => setDisplayColorPicker(true)}>
        <div style={{ background: `${value || '#aaa'}` }} className={classes.colorPickerSwatch} />
      </div>
      {displayColorPicker && (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={() => setDisplayColorPicker(false)} />
          <CompactPicker color={(value as string) || ''} onChange={(result) => handleChange && handleChange(name)(result.hex)} />
        </div>
      )}
    </div>
  )
}

export const WaoMenu = ({ items = [], onOpen }: WaoMenuProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const openMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
    setIsOpen(true)
    onOpen && onOpen()
  }

  const handleItemClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, item) => {
    e.stopPropagation()
    if (!item.keepOpen) handleClose(e)
    item.click && item.click()
  }

  const handleClose = (e: {}) => {
    setAnchorEl(undefined)
    setIsOpen(false)
  }

  return (
    <React.Fragment>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" className={classes.menu} onClick={(e) => openMenu(e)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={isOpen === true}
        onClose={(e) => handleClose(e)}
        PaperProps={{
          style: {
            minWidth: 200,
          },
        }}
      >
        {items.map((item) => {
          return (
            <MenuItem key={item.name} onClick={(e) => handleItemClick(e, item)}>
              {item.control}
            </MenuItem>
          )
        })}
      </Menu>
    </React.Fragment>
  )
}

const _toHtml5Date = (val: Date | string | undefined): string => {
  if (val === undefined) return ''
  if (val instanceof Date) val = val.toLocaleDateString()

  const m = moment(val)
  return m.isValid() ? m.format(moment.HTML5_FMT.DATETIME_LOCAL) : ''
}

const _toHtml5DateOnly = (val: string | undefined): string => {
  if (val === undefined) return ''
  const m = moment(val)
  return m.isValid() ? m.format(moment.HTML5_FMT.DATETIME_LOCAL).substring(0, 10) : ''
}
